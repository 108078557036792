import dayjs, { extend } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

extend(utc);
extend(timezone);
extend(isBetween);
extend(weekday);
extend(duration);
extend(isSameOrAfter);
extend(isSameOrBefore);

export default dayjs;

export function getTimeInAsia() {
	return dayjs(dayjs().tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss:SSS'));
}

export const DayOfWeek = {
	SUNDAY: 0,
	MONDAY: 1,
	TUESDAY: 2,
	WEDNESDAY: 3,
	THURSDAY: 4,
	FRIDAY: 5,
	SATURDAY: 6,
};
