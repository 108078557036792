import dayjs from 'dayjs';
import { formatDate, YMDHMS } from '@/plugins/formatDate';
import { getTimeInAsia } from '@/plugins/dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * 카카오 플러스친구 api
 * @data event_name : gtm event name과 동일
 * @data add_info : 클라이언트에서 보내줘야 할 부가 정보
 * 카카오플러스친구 사용자 정보 문서
 * https://docs.google.com/spreadsheets/d/1sMA0Nxtyk80hA6qZcUhQoeK66XVXFbjsv4GKCMrEthg/edit#gid=0
 **/
export function callKakaoPlusFriendApi(info) {
	const data = {
		event_name: info.event_name,
		add_info: info.add_info,
	};
	return axios
		.post('/api/kakaoFriend/updateUser', data)
		.then()
		.catch(function error() {
			console.error(error);
		});
}

export function isApp() {
	const userAgent = window.navigator.userAgent;
	const APP_USER_AGENT_REGEX = /<\s(.*[^\/])\/(.*[^\s])\s(.*[^\s])\s(.*[^\s])/;
	const regex = new RegExp(APP_USER_AGENT_REGEX);
	return userAgent.match(regex) !== null;
}

export function dataLayerPush(data) {
	window.dataLayer = window.dataLayer || [];
	dataLayer.push({
		...data,
		is_app: isApp(),
	});
}

// GTM - GAVirtualPageview (vue-router 페이지, /answer 하부 페이지)
export function GAVirtualPageview(path) {
	dataLayerPush({
		'content-name': path,
		event: 'GA-virtual-pageview',
	});
}

// GTM - onboardLanding
export function onboardLanding(user_session, user_referrer) {
	dataLayerPush({
		user_session: user_session,
		user_referrer: user_referrer,
		event: 'onboardLanding',
	});
}

// GTM - landingCTAButtonClick
export function landingCTAButtonClick(user_session, user_referrer, user_no, user_type, email) {
	dataLayerPush({
		user_session: user_session,
		user_referrer: user_referrer,
		user_no: user_no,
		user_type: user_type,
		email: email,
		event: 'landingCTAButtonClick',
	});
}

// GTM - onboardRegisterPage
export function onboardRegisterPage(user_session, user_referrer, local_storage_id) {
	dataLayerPush({
		user_session: user_session,
		user_referrer: user_referrer,
		local_storage_id: local_storage_id,
		event: 'onboardRegisterPage',
	});
}

// GTM - registerButtonClick
export function registerButtonClick(signup_type, where, local_storage_id) {
	// showLoading();

	dataLayerPush({
		signup_type: signup_type,
		where: where,
		local_storage_id: local_storage_id,
		event: 'registerButtonClick',
	});
	return true; // or return false;
}

// GTM - registerSucceeded
export function registerSucceeded(
	signup_date,
	user_referrer,
	signup_type,
	where,
	user_type,
	email,
	user_no,
	tags,
	revenue_amount,
	local_storage_id,
	optIn,
) {
	dataLayerPush({
		signup_date: signup_date,
		user_referrer: user_referrer,
		signup_type: signup_type,
		user_type: user_type,
		email: email,
		user_no: user_no,
		signup: 'Y',
		where: where,
		drip_tag: tags,
		revenue_amount: revenue_amount,
		local_storage_id: local_storage_id,
		optIn,
		event: 'registerSucceeded',
	});
}

// GTM - signin
export function signin(
	user_no,
	type,
	user_date,
	email,
	date_diff,
	user_university,
	user_major,
	mentor_company_name,
	mentor_job_position,
	user_class,
	where,
	local_storage_id,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: type,
		signup_date: user_date,
		email: email,
		date_diff: date_diff,
		user_university: user_university,
		user_major: user_major,
		mentor_company_name: mentor_company_name,
		mentor_job_position: mentor_job_position,
		user_class: user_class,
		where: where,
		local_storage_id: local_storage_id,
		event: 'signin',
	});
}

// GTM - clickNotification
export function clickNotification(user_no, type, email) {
	dataLayerPush({
		user_no: user_no,
		user_type: type,
		email: email,
		event: 'clickNotification',
	});
}

// GTM - clickNotiList
export function clickNotiList(user_no, type, email, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: type,
		email: email,
		where: where,
		event: 'clickNotiList',
	});
}

// GTM - clickBlogLink
export function clickBlogLink(user_no, type, email, where, link, local_storage_id) {
	dataLayerPush({
		user_no: user_no,
		user_type: type,
		email: email,
		where: where,
		link: link,
		local_storage_id: local_storage_id,
		event: 'clickBlogLink',
	});
}

// GTM - visitRequest
export function visitRequest(
	user_no,
	user_type,
	user_from_partnership_univ,
	request_type,
	where,
	b2b_user,
	contract_no,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		user_from_partnership_univ: user_from_partnership_univ,
		request_type: request_type,
		where: where,
		b2b_user: b2b_user,
		contract_no: contract_no,
		event: 'visitRequest',
	});
}

// GTM - onboardCaseList
export function onboardCaseList(user_no, user_type, sort_type, page_depth, category) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		sort_type: sort_type,
		page_depth: page_depth,
		category: category,
		event: 'onboardCaseList',
	});
}

// GTM - showPartnerNotice
export function showPartnerNotice(user_no, user_type, email, user_university, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		user_university: user_university,
		where: where,
		event: 'showPartnerNotice',
	});
}

// GTM - searchCase
export function searchCase(user_no, user_type, keyword, where, local_storage_id) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		search_keyword: keyword,
		where: where,
		local_storage_id: local_storage_id,
		event: 'searchCase',
	});
}

// GTM - searchRequest
export function searchRequest(user_no, user_type, keyword) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		search_keyword: keyword,
		event: 'searchRequest',
	});
}

// GTM - searchResult
export function searchResult(user_no, user_type, keyword, count, where, page_depth) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		search_keyword: keyword,
		count: count,
		where: where,
		page_depth: page_depth,
		event: 'searchResult',
	});
}

// GTM - readCase
export function readCase(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		search_keyword: info.keyword,
		request_no: info.req_no,
		request_company_name: info.company,
		request_job_position: info.job,
		number_of_answer: info.all_count,
		mentor_company_name: info.mentor_company,
		correspondence_company: info.cor_com,
		correspondence_industry: info.cor_in,
		correspondence_job: info.cor_job,
		answer_selected: info.chosen_count,
		rank: info.rank,
		api_partner_no: info.api_partner_no,
		search_count: info.search_count,
		banner: info.banner,
		event: 'readCase',
	});
}

// GTM - clickLike
export function clickLike(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		request_no: info.req_no,
		request_company_name: info.company,
		request_job_position: info.job,
		where: info.where,
		email: info.email,
		category: info.category,
		like_type: info.like_type,
		event: 'clickLike',
	});
}
// GTM - answerLiked
export function answerLiked(user_nickname, title, link, user_no, email) {
	dataLayerPush({
		user_nickname: user_nickname,
		title: title,
		link: link,
		user_no: user_no,
		email: email,
		event: 'answerLiked',
	});
}

// GTM - showUpdateProfile
export function showUpdateProfile(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		category: info.category,
		event: 'showUpdateProfile',
	});
}
// GTM - updateProfile_1
export function updateProfile_1(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		category: info.category,
		event: 'updateProfile_1',
	});
}
// GTM - updateProfile_2
export function updateProfile_2(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		category: info.category,
		event: 'updateProfile_2',
	});
}
// GTM - updateProfile_3
export function updateProfile_3(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		category: info.category,
		event: 'updateProfile_3',
	});
}
// GTM - updateProfile_4
export function updateProfile_4(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		category: info.category,
		event: 'updateProfile_4',
	});
}
// GTM - updateProfile_5
export function updateProfile_5(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		category: info.category,
		event: 'updateProfile_5',
	});
}
// GTM - updateProfile_6
export function updateProfile_6(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		category: info.category,
		event: 'updateProfile_6',
	});
}
// GTM - updateProfile
const UPDATE_PROFILE = 'updateProfile';
export function updateProfile(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		user_career: info.career, // 신입 / 경력
		user_university: info.univ, // 입력한 학교명
		user_major: info.major, // 입력한 전공명
		user_company: info.company, // 입력한 학교명
		user_companyGroup: info.companyGroup, //관심유형
		user_job: info.midCategory,
		user_job_topCategory: info.topCategory, // 입력한 학교명
		user_job_midCategory: info.midCategory,
		user_target_enter: info.employmentTimeName, // now, 6mo, 1yr, 2yr, later, undecided
		user_nickname: info.nickname,
		event: UPDATE_PROFILE,
	});
	callKakaoPlusFriendApi({
		event_name: UPDATE_PROFILE,
	});
}

// GTM - potentialPartnerUser
const POTENTIAL_PARTNER_USER = 'potentialPartnerUser';
export function potentialPartnerUser(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		user_email: info.user_email,
		user_university: info.user_university,
		user_major: info.user_major,
		date: formatDate(getTimeInAsia(), YMDHMS),
		event: POTENTIAL_PARTNER_USER,
	});
	callKakaoPlusFriendApi({
		event_name: POTENTIAL_PARTNER_USER,
	});
}

// GTM - completeRequest
export function completeRequest({
	user_no,
	user_type,
	request_type,
	where,
	category,
	subject,
	match_type,
	b2b_user,
	contract_no,
	request_company_name,
	request_job_position,
}) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		request_type: request_type,
		where: where,
		category: category,
		subject: subject,
		match_type: match_type,
		b2b_user: b2b_user,
		contract_no: contract_no,
		request_company_name: request_company_name,
		request_job_position: request_job_position,
		event: 'completeRequest',
	});
}

// GTM - buyMentoring
export function buyMentoring(user_no, user_type, revenue_amount, order_no, request_no) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		revenue_amount: revenue_amount,
		ecommerce: {
			purchase: {
				actionField: {
					id: order_no,
					revenue: revenue_amount,
					coupon: '',
				},
				products: [
					{
						id: request_no,
						name: 'mentoring',
						price: revenue_amount,
						coupon: '',
						quantity: 1,
					},
				],
			},
		},
		event: 'buyMentoring',
	});
}

// GTM - Revenue
export function Revenue(user_no, user_type, revenue_amount, rev_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		revenue_type: rev_type,
		revenue_amount: revenue_amount,
		event: 'Revenue',
	});
}

// GTM - readAnswer
export function readAnswer(user_no, user_type, req_no, rq_type, category) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		request_no: req_no,
		request_type: rq_type,
		category: category,
		event: 'readAnswer',
	});
}

// GTM - selectAnswer
export function selectAnswer(user_no, req_no, rq_type, category, credit_amount = 0) {
	dataLayerPush({
		user_no: user_no,
		request_no: req_no,
		request_type: rq_type,
		answer_no: '',
		correspondence_company: '',
		correspondence_industry: '',
		correspondence_job: '',
		correspondence_university: '',
		category: category,
		credit_amount,
		event: 'selectAnswer',
	});
}

// GTM - leaveReply
export function leaveReply(user_no, user_type, req_no, rq_type, where, category) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		request_no: req_no,
		request_type: rq_type,
		where: where,
		category: category,
		event: 'leaveReply',
	});
}
// GTM - leaveAnswer
export function leaveAnswer(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		email: info.user_id,
		user_level: info.user_level,
		correspondence_company: info.cor_com,
		correspondence_industry: info.cor_in,
		correspondence_job: info.cor_job,
		correspondence_interest: info.cor_interest,
		correspondence_university: info.cor_univ,
		request_no: info.req_no,
		request_type: info.req_type,
		where: info.where,
		category: info.category,
		length: info.length,
		re_mentoring: info.re_mentoring,
		event: 'leaveAnswer',
	});
}

// GTM - viewRequestWithCorrespondence
export function viewRequestWithCorrespondence() {
	dataLayerPush({
		event: 'viewRequestWithCorrespondence',
	});
}

// GTM - leaveAnswerWithCorrespondence
export function leaveAnswerWithCorrespondence() {
	dataLayerPush({
		event: 'leaveAnswerWithCorrespondence',
	});
}

// GTM - leaveLongAnswer - 답변이 390자 이상일 때
export function leaveLongAnswer(info) {
	window.dataLayer = window.dataLayer || [];
	dataLayer.push({
		user_no: info.user_no,
		user_type: info.user_type,
		email: info.user_id,
		request_no: info.req_no,
		request_type: info.req_type,
		where: info.where,
		category: info.category,
		length: info.length,
		is_app: info.is_app,
		event: 'leaveLongAnswer',
	});
}

// GTM - answerSelected
export function answerSelected(link, user_no, email, title, nickname_questioner, nickname_answerer) {
	dataLayerPush({
		link: link,
		user_no: user_no,
		email: email,
		title: title,
		nickname_questioner: nickname_questioner,
		nickname_answerer: nickname_answerer,
		event: 'answerSelected',
	});
}

// GTM - newReplytoAnswer
export function newReplytoAnswer(message, link, user_no, email, title, nickname_replier) {
	dataLayerPush({
		message: message,
		link: link,
		user_no: user_no,
		email: email,
		title: title,
		nickname_replier: nickname_replier,
		event: 'newReplytoAnswer',
	});
}

// GTM - viewRequestList
export function viewRequestList(user_no, user_type, event) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: event,
	});
}

// GTM - viewRequest
export function viewRequest(info) {
	dataLayerPush({
		user_no: info.user_no,
		user_type: info.user_type,
		email: info.email,
		user_level: info.user_level,
		correspondence_company: info.cor_com,
		correspondence_industry: info.cor_in,
		correspondence_job: info.cor_job,
		correspondence_university: info.cor_univ,
		request_no: info.req_no,
		request_type: info.req_type,
		where: info.where,
		re_mentoring: info.re_mentoring,
		event: 'viewRequest',
	});
}

// GTM - viewJobList
export function viewJobList(user_no, user_type, event) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: event,
	});
}

// GTM - viewJobPosting
export function viewJobPosting(user_no, user_type, job_no, com_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		job_posting_id: job_no,
		correspondence_job_interest: '',
		company_type: com_type,
		event: 'viewJobPosting',
	});
}

// GTM - clickApplyButton
export function clickApplyButton(
	user_no,
	user_type,
	email,
	where,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	industry,
	name,
	user_name,
	optIn,
	type,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		where: where,
		camp_id: camp_id,
		group_id: group_id,
		camp_category: camp_category,
		camp_mid_category: camp_mid_category,
		industry: industry,
		name: name,
		user_name: user_name,
		optIn: optIn,
		type: type,
		event: 'clickApplyButton',
	});
}

// GTM - clickOptOutButton
export function clickOptOutButton(user_no, user_type, email, where, type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		where: where,
		type: type,
		event: 'clickOptOutButton',
	});
}

// GTM - clickOptInButton
export function clickOptInButton(user_no, user_type, email, where, type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		where: where,
		type: type,
		event: 'clickOptInButton',
	});
}

// GTM - Applied
export function Applied(user_no, user_type, job_no, com_type, progress, invited) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		job_posting_id: job_no,
		correspondence_job_interest: '',
		company_type: com_type,
		resume_registered: progress,
		invited: invited,
		event: 'Applied',
	});
}

// GTM - onboardJobcard
export function onboardJobcard(ues, ref, user_type) {
	dataLayerPush({
		user_session: ues,
		user_referrer: ref,
		user_type: user_type,
		event: 'onboardJobcard',
	});
}

// GTM - adhocAuto
export function adhocAuto(user_no, user_type, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		where: where,
		event: 'adhocAuto',
	});
}

//GTM - onboardAItest
export function onboardAItest(user_no, user_type, b2b_user, contract_no) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		b2b_user: b2b_user,
		contract_no: contract_no,
		event: 'onboardAItest',
	});
}

// GTM - beginAItest
export function beginAItest(user_no, user_type, b2b_user, contract_no, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		where: where,
		b2b_user: b2b_user,
		contract_no: contract_no,
		event: 'beginAItest',
	});
}

// GTM - submitAItest
export function submitAItest(user_no, user_type, b2b_user, contract_no, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		where: where,
		b2b_user: b2b_user,
		contract_no: contract_no,
		event: 'submitAItest',
	});
}

// GTM = clickBuyAIButton
export function clickBuyAIButton(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'clickBuyAIButton',
	});
}

// GTM - buyAItest
export function buyAItest(user_no, user_type, revenue_amount) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		revenue_amount: revenue_amount, //실제 결제 금액
		event: 'buyAItest',
	});
}

// // GTM - onboardAIsample (나중에)
// export function onboardAIsample(){
//     dataLayerPush({
//         'user_no': '<?php echo $user_no; ?>',
//         'user_type': '<?php echo $user_type; ?>',
//         'event': "onboardAIsample"
//     })

// GTM - onboardMentoring
export function onboardMentoring(user_no, user_type, event) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: event,
	});
}

// GTM - onboardResume
export function onboardResume(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'onboardResume',
	});
}

// GTM - beginTutorial
export function beginTutorial(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'beginTutorial',
	});
}

// GTM - finishTutorial
export function finishTutorial(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'finishTutorial',
	});
}

// GTM - describeJob
export function describeJob(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'describeJob',
	});
}

// GTM - describeRequiredStrength
export function describeRequiredStrength(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'describeRequiredStrength',
	});
}

// GTM - describeMyStrength
export function describeMyStrength(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'describeMyStrength',
	});
}

// GTM - describeEpisode
export function describeEpisode(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'describeEpisode',
	});
}

// GTM - defineStrength
export function defineStrength(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'defineStrength',
	});
}

// GTM - describeRelevantEpisode
export function describeRelevantEpisode(user_no, user_type, email) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		event: 'describeRelevantEpisode',
	});
}

// GTM - completeCoverLetter
export function completeCoverLetter(user_no, user_type, email) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		event: 'completeCoverLetter',
	});
}

// GTM - resumeDraft
export function resumeDraft(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'resumeDraft',
	});
}

// GTM - finishResume
export function finishResume(user_no, user_type, date) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		date: date,
		event: 'finishResume',
	});
}

// GTM - clickMentorProfile
export function clickMentorProfile(user_no, user_type, email, mentor_no, mentor_company_name, mentor_job_position) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		mentor_no: mentor_no,
		mentor_company_name: mentor_company_name,
		mentor_job_position: mentor_job_position,
		event: 'clickMentorProfile',
	});
}

// GTM - clickMentorBanner
export function clickMentorBanner(user_no, user_type, email) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		event: 'clickMentorBanner',
	});
}

// GTM - onboardPriceList
export function onboardPriceList(user_no, user_type, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		where: where, //contents, ai
		event: 'onboardPriceList',
	});
}

// GTM - selectPG
export function selectPG(
	user_no,
	user_type,
	where,
	user_email,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	camp_bottom_category,
	revenue_amount,
	link,
	camp_onoff,
	category,
	industry,
	revenue_type,
	local_storage_id,
	vod_no,
	image_link,
	name,
	user_name,
	period,
	recruit_end,
) {
	link = typeof link !== 'undefined' ? link : '';
	revenue_amount = typeof revenue_amount !== 'undefined' ? revenue_amount : 11900;
	camp_id = typeof camp_id !== 'undefined' ? camp_id : null;
	group_id = typeof group_id !== 'undefined' ? group_id : null;
	camp_category = typeof camp_category !== 'undefined' ? camp_category : null;
	camp_mid_category = typeof camp_mid_category !== 'undefined' ? camp_mid_category : null;
	camp_bottom_category = typeof camp_bottom_category !== 'undefined' ? camp_bottom_category : null;

	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		where: where, //mentoring, contents, ai
		email: user_email,
		camp_id: camp_id,
		group_id: group_id,
		camp_category: camp_category,
		camp_mid_category: camp_mid_category,
		camp_bottom_category: camp_bottom_category,
		revenue_amount: revenue_amount,
		link: link,
		camp_onoff: camp_onoff,
		category: category,
		industry: industry,
		revenue_type: revenue_type,
		local_storage_id: local_storage_id,
		vod_no: vod_no,
		image_link: image_link,
		name: name,
		user_name: user_name,
		period: period,
		recruit_end: recruit_end,
		event: 'selectPG',
	});
}

// GTM - selectPG_B2B
export function selectPG_B2B(
	user_no,
	user_type,
	where,
	email,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	revenue_amount,
	link,
	industry,
	local_storage_id,
	category,
	revenue_type,
) {
	link = typeof link !== 'undefined' ? link : '';
	revenue_amount = typeof revenue_amount !== 'undefined' ? revenue_amount : 11900;
	camp_id = typeof camp_id !== 'undefined' ? camp_id : null;
	group_id = typeof group_id !== 'undefined' ? group_id : null;
	camp_category = typeof camp_category !== 'undefined' ? camp_category : null;
	camp_mid_category = typeof camp_mid_category !== 'undefined' ? camp_mid_category : null;

	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		where: where, //mentoring, contents, ai
		email: email,
		camp_id: camp_id,
		group_id: group_id,
		camp_category: camp_category,
		camp_mid_category: camp_mid_category,
		revenue_amount: revenue_amount,
		link: link,
		category: category,
		industry: industry,
		revenue_type: revenue_type,
		local_storage_id: local_storage_id,
		event: 'selectPG_B2B',
	});
}

// GTM - buyContents
const BUY_CONTENTS = 'buyContents';
export function buyContents(
	user_no,
	user_type,
	price_plan,
	revenue_amount,
	revenue_type,
	expiration_date,
	buy_mentoring_no,
	item_no,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		price_plan: price_plan, // 1, 3, 6
		revenue_amount: revenue_amount, //실제 결제 금액
		revenue_type: revenue_type,
		expiration_date: expiration_date,
		ecommerce: {
			purchase: {
				actionField: {
					id: buy_mentoring_no,
					revenue: revenue_amount,
					coupon: '',
				},
				products: [
					{
						id: item_no,
						name: 'contents',
						category: revenue_type,
						variant: price_plan,
						price: revenue_amount,
						coupon: '',
						quantity: 1,
					},
				],
			},
		},
		event: BUY_CONTENTS,
	});
	callKakaoPlusFriendApi({
		event_name: BUY_CONTENTS,
	});
}

// GTM - buyContentsWithCoupon
// 삼성에듀에 대시보드를 제공하기 위해 임시로 사용하는 gtm
// buyContents와 함께 발동
const BUY_CONTENTS_WITH_COUPON = 'buyContentsWithCoupon';
export function buyContentsWithCoupon(
	user_no,
	user_type,
	price_plan,
	revenue_amount,
	revenue_type,
	expiration_date,
	buy_mentoring_no,
	item_no,
	coupon_name = '',
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		price_plan: price_plan, // 1, 3, 6
		revenue_amount: revenue_amount, //실제 결제 금액
		revenue_type: revenue_type,
		expiration_date: expiration_date,
		ecommerce: {
			purchase: {
				actionField: {
					id: buy_mentoring_no,
					revenue: revenue_amount,
					coupon: '',
				},
				products: [
					{
						id: item_no,
						name: 'contents',
						category: revenue_type,
						variant: price_plan,
						price: revenue_amount,
						coupon: '',
						quantity: 1,
					},
				],
			},
		},
		coupon_name: coupon_name,
		event: BUY_CONTENTS_WITH_COUPON,
	});
}

// GTM - paymentFailure
export function paymentFailure(user_no, user_type, message, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		message: message, // 에러 메시지
		where: where, // 결제 method
		event: 'paymentFailure',
	});
}

// GTM - onboardMyPage
export function onboardMyPage(user_no, user_type, email) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		event: 'onboardMyPage',
	});
}

// GTM - registerB2BEduCoupon
export function registerB2BEduCoupon(user_no, user_type, email, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		where: where, // 제휴기관명(고려대학교, 경동대학교 등)
		event: 'registerB2BEduCoupon',
	});
}

// GTM - registerB2CEduCoupon
export function registerB2CEduCoupon(user_no, user_type, email, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		where: where, // 제휴기관명(학생독립만세 등)
		event: 'registerB2CEduCoupon',
	});
}

/*
 * VOD - GTM
 * */
export function onboardVODlist(user_no, user_type, email) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		event: 'onboardVODlist',
	});
}
export function viewVODDetail(user_no, user_type, email, vod_no, revenue_amount, vod_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		vod_no: vod_no,
		revenue_amount: revenue_amount,
		vod_type: vod_type,
		event: 'viewVODDetail',
	});
}
export function buyVOD(user_no, user_type, email, vod_no, revenue_amount) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		vod_no: vod_no,
		revenue_amount: revenue_amount,
		event: 'buyVOD',
	});
}

/*
 * EDU - GTM
 * */

// GTM - onboardEduLanding
export function onboardEduLanding(user_no, user_type, user_id, local_storage_id) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: user_id,
		local_storage_id: local_storage_id,
		event: 'onboardEduLanding',
	});
}

// GTM - writeApplication
export function writeApplication(
	user_no,
	user_type,
	user_id,
	link,
	name,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	camp_bottom_category,
	revenue_amount,
	count,
	camp_onoff,
	industry,
	local_storage_id,
	category,
	revenue_type,
	where,
	image_link,
	period,
) {
	let params = {
		user_no: user_no,
		user_type: user_type,
		email: user_id,
		link: link,
		name: name,
		camp_id: camp_id,
		group_id: group_id,
		camp_category: camp_category,
		camp_mid_category: camp_mid_category,
		camp_bottom_category: camp_bottom_category,
		revenue_amount: revenue_amount,
		count: count,
		camp_onoff: camp_onoff,
		industry: industry,
		local_storage_id: local_storage_id,
		category: category,
		revenue_type: revenue_type,
		where,
		image_link: image_link,
		period: period,
		event: 'writeApplication',
	};
	if (where === 'educamp') {
		params.items = {
			id: group_id,
			google_business_vertical: 'education',
		};
	}
	dataLayerPush(params);
}

// GTM - writeApplication_B2B
export function writeApplication_B2B(user_no, user_type, email, local_storage_id, camp_id, group_id, link) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		local_storage_id: local_storage_id,
		camp_id: camp_id,
		group_id: group_id,
		link: link,
		event: 'writeApplication_B2B',
	});
}

// GTM - beginDiagnosis
export function beginDiagnosis(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'beginDiagnosis',
	});
}

// GTM - finishDiagnosis
export function finishDiagnosis(user_no, user_type, user_job, email, name) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		user_job: user_job,
		email: email,
		name: name,
		event: 'finishDiagnosis',
	});
}

// GTM - viewEduList
export function viewEduList(link, user_no, user_type, user_id, camp_category, local_storage_id, where) {
	dataLayerPush({
		link: link,
		user_no: user_no,
		user_type: user_type,
		email: user_id,
		camp_category: camp_category,
		local_storage_id: local_storage_id,
		where: where,
		event: 'viewEduList',
	});
}

// GTM - viewEduList_B2B
export function viewEduList_B2B(user_no, user_type, email, local_storage_id, link, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		local_storage_id: local_storage_id,
		link: link,
		where: where,
		event: 'viewEduList_B2B',
	});
}

// GTM - showApplyNotice_B2B
export function showApplyNotice_B2B({ user_no, user_type, email, local_storage_id, camp_id, group_id, link }) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		local_storage_id: local_storage_id,
		camp_id: camp_id,
		group_id: group_id,
		link: link,
		event: 'showApplyNotice_B2B',
	});
}

// GTM - completeApply_B2B
export function completeApply_B2B({ user_no, user_type, email, camp_id, group_id, link }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		camp_id,
		group_id,
		link,
		event: 'completeApply_B2B',
	});
}

// GTM - clickApplyNoticeCTA_B2B
export function clickApplyNoticeCTA_B2B({
	user_no,
	user_type,
	email,
	local_storage_id,
	camp_id,
	group_id,
	link,
	type,
}) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		local_storage_id,
		camp_id,
		group_id,
		link,
		type,
		event: 'clickApplyNoticeCTA_B2B',
	});
}

// GTM - viewEduDetail
export function viewEduDetail(
	user_no,
	user_type,
	user_id,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	camp_onoff,
	category,
	industry,
	revenue_amount,
	title,
	link,
	image_link,
	local_storage_id,
	where,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: user_id,
		camp_id: camp_id,
		group_id: group_id,
		camp_category: camp_category,
		camp_mid_category: camp_mid_category,
		camp_onoff: camp_onoff,
		category: category,
		industry: industry,
		revenue_amount: revenue_amount,
		title: title,
		link: link,
		image_link: image_link,
		local_storage_id: local_storage_id,
		where: where,
		event: 'viewEduDetail',
	});
}

// GTM - viewEduDetail_B2B
export function viewEduDetail_B2B(user_no, user_type, email, camp_id, where, local_storage_id) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		camp_id: camp_id,
		where: where,
		local_storage_id: local_storage_id,
		event: 'viewEduDetail_B2B',
	});
}

// GTM - clickSchedule
export function clickSchedule(
	user_no,
	user_type,
	email,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	camp_onoff,
	industry,
	revenue_amount,
	click_count,
	local_storage_id,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		camp_id: camp_id,
		group_id: group_id,
		camp_category: camp_category,
		camp_mid_category: camp_mid_category,
		camp_onoff: camp_onoff,
		industry: industry,
		revenue_amount: revenue_amount,
		click_count: click_count,
		local_storage_id: local_storage_id,
		event: 'clickSchedule',
	});
}

// GTM - VedSevenTimes
const VED_SEVEN_TIMES = 'VedSevenTimes';
export function VedSevenTimes(
	user_no,
	user_type,
	email,
	camp_id,
	where,
	group_id,
	camp_category,
	local_storage_id,
	revenue_amount,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		camp_id: camp_id,
		where: where,
		group_id: group_id,
		camp_category: camp_category,
		local_storage_id: local_storage_id,
		revenue_amount: revenue_amount,
		event: VED_SEVEN_TIMES,
	});
}

// GTM - clickEduBanner
export function clickEduBanner(
	user_no,
	user_type,
	email,
	banner_type,
	banner_at,
	banner_to,
	camp_id,
	group_id,
	local_storage_id,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		banner_type: banner_type,
		banner_at: banner_at,
		banner_to: banner_to,
		camp_id: camp_id,
		group_id: group_id,
		local_storage_id: local_storage_id,
		event: 'clickEduBanner',
	});
}

// GTM - onboardReviewPage
export function onboardReviewPage(user_no, user_type, email) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		event: 'onboardReviewPage',
	});
}

// GTM - requestChat
export function requestChat(user_no, user_type, local_storage_id) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		local_storage_id: local_storage_id,
		event: 'requestChat',
	});
}

// GTM - anotherSchedule
export function anotherSchedule(user_no, user_type, camp_category, camp_mid_category, industry, local_storage_id) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		camp_category: camp_category,
		camp_mid_category: camp_mid_category,
		industry: industry,
		local_storage_id: local_storage_id,
		event: 'anotherSchedule',
	});
}

// GTM - submitSchedule
export function submitSchedule(user_no, user_type) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		event: 'submitSchedule',
	});
}

// GTM - outboundContactPopup
export function outboundContactPopup(
	user_no,
	user_type,
	email,
	group_id,
	camp_category,
	camp_mid_category,
	industry,
	local_storage_id,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		group_id: group_id,
		camp_category: camp_category,
		camp_mid_category: camp_mid_category,
		industry: industry,
		local_storage_id: local_storage_id,
		event: 'outboundContactPopup',
	});
}

// GTM - submitOutboundContact
export function submitOutboundContact(
	user_no,
	user_type,
	email,
	group_id,
	camp_category,
	camp_mid_category,
	industry,
	local_storage_id,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		group_id: group_id,
		camp_category: camp_category,
		camp_mid_category: camp_mid_category,
		industry: industry,
		local_storage_id: local_storage_id,
		event: 'submitOutboundContact',
	});
}

// GTM - buyEduCamp
const BUY_EDU_CAMP = 'buyEduCamp';
export function buyEduCamp(
	user_no,
	user_type,
	email,
	name,
	link,
	revenue_amount,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	camp_bottom_category,
	date_diff,
	camp_onoff,
	category,
	actionField,
	camp,
	link_agreement,
	membership,
	revenue_type,
	local_storage_id,
	where,
	period,
) {
	let params = {
		link: link,
		user_no: user_no,
		user_type: user_type,
		email: email,
		camp_id: camp_id,
		group_id: group_id,
		camp_category: camp_category,
		camp_mid_category: camp_mid_category,
		camp_bottom_category: camp_bottom_category,
		revenue_amount: revenue_amount,
		name: name,
		category: category,
		revenue_type: revenue_type,
		membership: membership,
		date_diff: date_diff,
		camp_onoff: camp_onoff,
		local_storage_id: local_storage_id,
		link_agreement: link_agreement,
		ecommerce: {
			purchase: {
				actionField: actionField
					? {
							id: actionField.id, // Transaction ID. Required for purchases and refunds.
							revenue: actionField.revenue, // Total transaction value (incl. tax and shipping)
							coupon: actionField.coupon,
					  }
					: null,
				products:
					camp && actionField
						? [
								{
									name: camp.name,
									id: camp.no,
									price: camp.price,
									brand: camp.brand,
									category: camp.category,
									variant: camp.onoff,
									coupon: actionField.coupon,
									quantity: 1,
								},
						  ]
						: null,
			},
		},
		where: where,
		period: period,
		event: BUY_EDU_CAMP,
	};
	if (where === 'educamp') {
		params.items = {
			id: group_id,
			google_business_vertical: 'education',
		};
	}
	dataLayerPush(params);
	callKakaoPlusFriendApi({
		event_name: BUY_EDU_CAMP,
		add_info: {
			group_no: group_id,
		},
	});
}

// GTM - viewCampRequest
export function viewCampRequest(user_no, user_type, user_id, local_storage_id) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: user_id,
		local_storage_id: local_storage_id,
		event: 'viewCampRequest',
	});
}

// GTM - clickCampRequestButton
export function clickCampRequestButton(user_no, user_type, user_id, local_storage_id) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: user_id,
		local_storage_id: local_storage_id,
		event: 'clickCampRequestButton',
	});
}

// GTM - completeCampRequest
export function completeCampRequest(user_no, user_type, user_id, name, camp_no, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: user_id,
		name: name,
		request_no: camp_no,
		where: where,
		event: 'completeCampRequest',
	});
}

// GTM - clickRecentlyViewedCamp
export function clickRecentlyViewedCamp(where) {
	dataLayerPush({
		where: where,
		event: 'clickRecentlyViewedCamp',
	});
}

// // GTM - clickWriteApplicationButton
// export function clickWriteApplicationButton(email, user_no, user_type, invited) {
// 	dataLayerPush({
// 		email: email,
// 		user_no: user_no,
// 		user_type: user_type,
// 		invited: invited,
// 		event: 'clickWriteApplicationButton'
// 	});
// }

// GTM - clickPincodeSetButton
export function clickPincodeSetButton(email, user_no, user_type) {
	dataLayerPush({
		email: email,
		user_no: user_no,
		user_type: user_type,
		event: 'clickPincodeSetButton',
	});
}

// clickNDAButton
export function clickNDAButton(
	user_no,
	user_type,
	email,
	name,
	link,
	camp_id,
	group_id,
	link_kakao,
	link_kakao_pw,
	where,
	membership,
	mentor_nickname,
	camp_mid_category,
) {
	dataLayerPush({
		email: email,
		user_no: user_no,
		user_type: user_type,
		name: name,
		link: link,
		camp_id: camp_id,
		group_id: group_id,
		link_kakao: link_kakao,
		link_kakao_pw: link_kakao_pw,
		date: formatDate(getTimeInAsia(), YMDHMS),
		where: where,
		membership: membership,
		mentor_nickname: mentor_nickname,
		camp_mid_category: camp_mid_category,
		event: 'clickNDAButton',
	});
}

/*
 *  edu - 리드멘토 신청
 *  ---------
 */

// GTM - onboardLeadMentorApply
export function onboardLeadMentorApply(user_no, user_type, email, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		where: where,
		event: 'onboardLeadMentorApply',
	});
}

// GTM - clickLeadMentorApply
export function clickLeadMentorApply(user_no, user_type, email, where) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		where: where,
		event: 'clickLeadMentorApply',
	});
}

/*
 *  쿠폰
 *  ---------
 */

// GTM - onboardCouponBook
export function onboardCouponBook(user_no, user_type, email, local_storage_id) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		local_storage_id: local_storage_id,
		event: 'onboardCouponBook',
	});
}

// GTM - downloadCoupon
export function downloadCoupon(user_no, user_type, email, number, where, category) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		number: number,
		where: where,
		category: category,
		event: 'downloadCoupon',
	});
}

// GTM - onboardMyCoupon
export function onboardMyCoupon(user_no, user_type, email, local_storage_id) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		local_storage_id: local_storage_id,
		event: 'onboardMyCoupon',
	});
}

/*
 *  BIZ - GTM
 *  ---------
 */

// GTM - viewResume
export function viewResume(user_no, job_posting_id, contents_type) {
	dataLayerPush({
		user_no: user_no,
		job_posting_id: job_posting_id,
		contents_typ: contents_type,
		event: 'viewResume',
	});
}

// GTM - proposal
export function proposal(user_no, user_email, user_name, link, invited, job_posting_id, com_name, com_job) {
	dataLayerPush({
		user_no: user_no,
		email: user_email,
		name: user_name,
		link: link,
		invited: invited,
		job_posting_id: job_posting_id,
		company: com_name,
		job_title: com_job,
		event: 'proposal',
	});
}

// GTM - passResume
export function passResume(user_no, job_posting_id) {
	dataLayerPush({
		user_no: user_no,
		job_posting_id: job_posting_id,
		event: 'passResume',
	});
}

// GTM - acceptProposal
export function acceptProposal(user_no, date, job_posting_id) {
	dataLayerPush({
		user_no: user_no,
		date: date,
		job_posting_id: job_posting_id,
		event: 'acceptProposal',
	});
}

// GTM - interviewSchedule
export function interviewSchedule(user_no, job_posting_id) {
	dataLayerPush({
		user_no: user_no,
		job_posting_id: job_posting_id,
		event: 'interviewSchedule',
	});
}

// GTM - confirmSchedule
export function confirmSchedule(user_no, job_posting_id) {
	dataLayerPush({
		user_no: user_no,
		job_posting_id: job_posting_id,
		event: 'confirmSchedule',
	});
}

// GTM - addInterview
export function addInterview(user_no, job_posting_id) {
	dataLayerPush({
		user_no: user_no,
		job_posting_id: job_posting_id,
		event: 'addInterview',
	});
}

// GTM - passInterview
export function passInterview(user_no, job_posting_id) {
	dataLayerPush({
		user_no: user_no,
		job_posting_id: job_posting_id,
		event: 'passInterview',
	});
}

/*
 * Category - 현직자 인증
 * */

// GTM - mentorAuthPopup
export function mentorAuthPopup(user_no, user_type, email) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		event: 'mentorAuthPopup',
	});
}

// GTM - mentorRegisterButtonClick
export function mentorRegisterButtonClick(user_no, user_type, email, company, job) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		mentor_company_name: company,
		mentor_job_position: job,
		event: 'mentorRegisterButtonClick',
	});
}

// GTM - requestMentorAuth
export function requestMentorAuth(user_no, user_type, email, auth_type, pincode, company, job) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		auth_type: auth_type,
		pincode: pincode,
		mentor_company_name: company,
		mentor_job_position: job,
		event: 'requestMentorAuth',
	});
}

// GTM - mentorRegisterSucceeded
export function mentorRegisterSucceeded(user_no, user_type, email, company, job, date) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		mentor_company_name: company,
		mentor_job_position: job,
		date: date,
		event: 'mentorRegisterSucceeded',
	});
}

// GTM - mentorRegisterFirstTime
export function mentorRegisterFirstTime(user_no, user_type, email, company, job, date) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		mentor_company_name: company,
		mentor_job_position: job,
		date: date,
		event: 'mentorRegisterFirstTime',
	});
}

// GTM - onboardMentorProfile
export function onboardMentorProfile(user_no, user_type, email, mentor_no, mentor_company_name, mentor_job_position) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		mentor_no: mentor_no,
		mentor_company_name: mentor_company_name,
		mentor_job_position: mentor_job_position,
		event: 'onboardMentorProfile',
	});
}

// GTM - clickMentorProfileTab
export function clickMentorProfileTab(
	user_no,
	user_type,
	email,
	mentor_no,
	mentor_company_name,
	mentor_job_position,
	where,
) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		mentor_no: mentor_no,
		mentor_company_name: mentor_company_name,
		mentor_job_position: mentor_job_position,
		where: where,
		event: 'clickMentorProfileTab',
	});
}

//GTM - AffiliateGtm Common ( onboardUnivPage, onboardApplyPartnerUser, clickApplyPartnerUser, completeApplyPartnerUser)
export function affiliateNonWhere(data, eventType) {
	dataLayerPush({
		user_session: data.user_session,
		user_referer: data.http_referer,
		user_no: data.user_no,
		user_type: data.user_type,
		email: data.email,
		expiration_date: data.expiration_date,
		event: eventType,
	});
}

//GTM - AffiliateGtm Common2 ( onboardUnivPage, onboardApplyPartnerUser, clickApplyPartnerUser, completeApplyPartnerUser)
export function affiliateWhere(data, where, eventType) {
	dataLayerPush({
		user_session: data.user_session,
		user_referer: data.http_referer,
		user_no: data.user_no,
		user_type: data.user_type,
		email: data.email,
		where: where,
		event: eventType,
	});
}

//GTM - clickAffiliatedPartner
export function clickAffiliatedPartner(data, where) {
	dataLayerPush({
		user_session: data.user_session,
		user_referer: data.http_referer,
		user_no: data.user_no,
		user_type: data.user_type,
		email: data.email,
		where: where,
		event: 'clickAffiliatedPartner',
	});
}
//GTM - requestAffiliatePartnership
export function requestAffiliatePartnership(data, where) {
	dataLayerPush({
		user_session: data.user_session,
		user_referer: data.http_referer,
		user_no: data.user_no,
		user_type: data.user_type,
		email: data.email,
		where: where,
		event: 'requestAffiliatePartnership',
	});
}

//GTM - clickCaseButton
export function clickCaseButton(data, where, type) {
	dataLayerPush({
		user_no: data.user_no,
		user_type: data.user_type,
		request_no: data.request_no,
		request_company_name: data.company_name,
		request_job_position: data.job_position,
		email: data.email,
		where: where,
		type: type,
		event: 'clickCaseButton',
	});
}

// GTM - readInsight
export function readInsight(user_no, user_type, email, insight_no) {
	dataLayerPush({
		user_no: user_no,
		user_type: user_type,
		email: email,
		insight_no: insight_no,
		event: 'readInsight',
	});
}

// GTM - onboardUpgradePage
export function onboardUpgradePage(user_no, email) {
	dataLayerPush({
		user_no: user_no,
		email: email,
		event: 'onboardUpgradePage',
	});
}

// GTM - upgradePlan
export function upgradePlan(user_no, email, price) {
	dataLayerPush({
		user_no: user_no,
		email: email,
		price: price,
		event: 'upgradePlan',
	});
}

// GTM - onboardEventPage_Supporters
export function onboardEventPage_Supporters(user_no, email) {
	dataLayerPush({
		user_no: user_no,
		email: email,
		event: 'onboardEventPage_Supporters',
	});
}

/**
 * GA - revenue
 * */

// GA - revenueAddToCartEduCamp
export function revenueAddToCartEduCamp(camp) {
	dataLayerPush({
		event: 'addToCart',
		ecommerce: {
			currencyCode: 'KRW',
			add: {
				// 'add' actionFieldObject measures.
				products: [
					{
						//  adding a product to a shopping cart.
						name: camp.name,
						id: camp.no,
						price: camp.price,
						brand: camp.brand,
						category: camp.category,
						variant: camp.onoff,
						coupon: '',
					},
				],
			},
		},
	});
}

// GA - revenueCheckOutEduCamp
export function revenueCheckOutEduCamp(camp) {
	dataLayerPush({
		event: 'checkout',
		ecommerce: {
			checkout: {
				products: [
					{
						name: camp.name,
						id: camp.no,
						price: camp.price,
						brand: camp.brand,
						category: camp.category,
						variant: camp.onoff,
						coupon: camp.coupon,
					},
				],
			},
		},
	});
}

// GA - revenuePurchaseEduCamp
export function revenuePurchaseEduCamp(actionField, camp) {
	dataLayerPush({
		ecommerce: {
			purchase: {
				actionField: {
					id: actionField.id, // Transaction ID. Required for purchases and refunds.
					revenue: actionField.revenue, // Total transaction value (incl. tax and shipping)
					coupon: actionField.coupon,
				},
				products: [
					{
						name: camp.name,
						id: camp.no,
						price: camp.price,
						brand: camp.brand,
						category: camp.category,
						variant: camp.onoff,
						coupon: actionField.coupon,
					},
				],
			},
		},
	});
}

// GTM - clickLeadGen
// 블로그 CBC 프로모션 랜딩 트랙킹 체크용
export function clickLeadGen(email) {
	dataLayerPush({
		email: email,
		event: 'clickLeadGen',
	});
}

// GTM - clickMovingToDetail
// 리드멘토 유도 화면 자세히 알아보기 클릭
export function clickMovingToDetail(user_no, user_type, email) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		event: 'clickMovingToDetail',
	});
}

// GTM - optIn
// 마케팅, 정보 수신 동의
export function optIn({ user_no, user_type, email, where, type }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		where,
		type,
		event: 'optIn',
	});
}

// GTM - optOut
// 마케팅, 정보 수신 거부
export function optOut({ user_no, user_type, email, where, type }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		where,
		type,
		event: 'optOut',
	});
}

// GTM - buyEduCamp_B2B
// 채용연계형 직부캠 신청
export function buyEduCamp_B2B({
	user_no,
	user_type,
	email,
	local_storage_id,
	link,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	revenue_amount,
	name,
	date_diff,
	where,
}) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		local_storage_id,
		link,
		camp_id,
		group_id,
		camp_category,
		camp_mid_category,
		revenue_amount,
		name,
		date_diff,
		where,
		event: 'buyEduCamp_B2B',
	});
}

// GTM - showAppBanner
// 앱 다운로드 모달 출력
export function showAppBanner({ user_no, user_type, email, where }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		where,
		event: 'showAppBanner',
	});
}

// GTM - clickAppBanner
// 앱 다운로드 모달/배너 클릭
export function clickAppBanner({ user_no, user_type, email, where }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		where,
		event: 'clickAppBanner',
	});
}

// GTM - clickHomeBanner
export function clickHomeBanner({ user_no, user_type, banner_type, banner_order, category, link }) {
	dataLayerPush({
		user_no,
		user_type,
		banner_type,
		banner_order,
		category,
		link,
		event: 'clickHomeBanner',
	});
}

export function clickMatchingApplyCTA({ user_no, user_type, email, where, type }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		where,
		type,
		event: 'clickMatchingApplyCTA',
	});
}

// GTM - onboardMentorPage
export function onboardMentorPage({ user_no, user_type, email }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		event: 'onboardMentorPage',
	});
}

export function onboardPurchaserPage(user_no, user_type, email) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		event: 'onboardPurchaserPage',
	});
}

export function clickChatRoomCTA(user_no, user_type, email, where) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		where,
		event: 'clickChatRoomCTA',
	});
}

export function searchContent({ user_no, user_type, email, search_keyword, local_storage_id, where, type }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		search_keyword,
		local_storage_id,
		where,
		type,
		event: 'searchContent',
	});
}

export function onboardLeadMentorInvite({ user_no, user_type, email }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		event: 'onboardLeadMentorInvite',
	});
}

export function clickLeadMentorInviteCTA({ user_no, user_type, email, where }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		where,
		event: 'clickLeadMentorInviteCTA',
	});
}

export function mentorTutorial4({ user_no, user_type, email }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		event: 'mentorTutorial-4',
	});
}

export function mentorTutorial5({ user_no, user_type, email }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		event: 'mentorTutorial-5',
	});
}

// GTM - impression
/**
 * 광고가 노출되어 impression이 발생된 경우
 * @param user_no
 * @param user_type
 * @param email
 * @param order
 * @param type
 * @param category
 * @param where: timeline, readCase
 * @param job_posting_id: 광고주 번호
 * @param partner_name: 우아한청년들, 연대릴레이특강, CBC, CBC반도체
 * @param rolling_order: 배너 순서
 */
export function impression({
	user_no,
	user_type,
	type,
	email,
	where,
	category,
	order,
	job_posting_id,
	partner_name,
	rolling_order,
}) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		type,
		category,
		order,
		where,
		job_posting_id,
		partner_name,
		rolling_order,
		event: 'impression',
	});
}

// GTM - click_ad_banner
/**
 * 노출된 광고를 클릭한 경우
 * @param user_no
 * @param user_type
 * @param email
 * @param where
 * @param order
 * @param category
 * @param type
 * @param job_posting_id
 * @param partner_name
 * @param rolling_order: 배너 순서
 */
export function clickAdBanner({
	user_no,
	user_type,
	email,
	where,
	order,
	category,
	type,
	job_posting_id,
	partner_name,
	rolling_order,
}) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		where,
		order,
		category,
		type,
		job_posting_id,
		partner_name,
		rolling_order,
		event: 'click_ad_banner',
	});
}

// GTM - exchangeMoney
/**
 * 현금환급 신청하기 완료 시 발동
 * @param user_no
 * @param user_type
 * @param email
 * @param user_level
 * @param category
 * @param revenue_amount: 환급 신청한 금액 (10000, 30000, 50000, ....)
 */
export function exchangeMoney({ user_no, user_type, email, user_level, revenue_amount }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		user_level,
		revenue_amount,
		event: 'exchangeMoney',
	});
}

// GTM - exchangeGift
/**
 * 기프티콘 교환하기 완료 시 발동
 * @param user_no
 * @param user_type
 * @param email
 * @param user_level
 * @param brand_name 브랜드명
 * @param gift_name 교환 신청한 기프티콘의 이름
 * @param revenue_amount 교환 신청한 기프티콘의 금액 (3500 , ....)
 */
export function exchangeGift({ user_no, user_type, email, user_level, brand_name, gift_name, revenue_amount }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		user_level,
		brand_name,
		gift_name,
		revenue_amount,
		event: 'exchangeGift',
	});
}

// GTM - clickCommunityToEduCTA
/**
 * 자소서 첨삭 답변 페이지에서 eduLanding으로 가는 button 클릭시 발동
 * @param user_no
 * @param user_type
 * @param email
 * @param where
 * @param category
 * @param type
 * @param link
 */
export function clickCommunityToEduCTA({ user_no, user_type, email, where, category, type, link }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		where,
		category,
		type,
		link,
		event: 'clickCommunityToEduCTA',
	});
}

/**
 * 직무부트캠프 결제 후 구매 후 혜택 페이지 내의 배너 CTA를 클릭했을 때 trigger
 * @param user_no
 * @param user_type
 * @param email
 * @param banner_order
 * @param banner_type
 */
export function clickPurchaseBenefitCTA({ user_no, user_type, email, banner_order, banner_type }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		banner_order,
		banner_type,
		event: 'clickPurchaseBenefitCTA',
	});
}

/**
 * "아직 준비된 자소서가 없으신가요?" 클릭시 발동
 * @param user_no
 * @param user_type
 * @param email
 * @param membership_type / b2b / b2c / none
 */
export function clickAICoverLetter({ user_no, user_type, email, membership_type }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		membership_type,
		event: 'clickAICoverLetter',
	});
}

/**
 * "AI로 자소서 만들기" 클릭시 발동
 * @param user_no
 * @param user_type
 * @param email
 */
export function submitAICoverLetter({ user_no, user_type, email }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		event: 'submitAICoverLetter',
	});
}

/**
 * "현직자 첨삭받기" 클릭시 발동
 * @param user_no
 * @param user_type
 * @param email
 */
export function requestMentorFeedback({ user_no, user_type, email }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		event: 'requestMentorFeedback',
	});
}

/**
 * "내용 복사" 클릭시 발동
 * @param user_no
 * @param user_type
 * @param email
 */
export function copyAICoverLetter({ user_no, user_type, email }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		event: 'copyAICoverLetter',
	});
}

/**
 * 헤더 menteemission banner 클릭시 발동
 *
 */
export function showMenteeMission({ user_no, user_type, email }) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		event: 'showMenteeMission',
	});
}

export function selectPG_showCouponCTA({
	user_no,
	user_type,
	email,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	revenue_amount,
}) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		camp_id,
		group_id,
		camp_category,
		camp_mid_category,
		revenue_amount,
		event: 'selectPG_showCouponCTA',
	});
}

export function selectPG_clickCouponCTA({
	user_no,
	user_type,
	email,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	revenue_amount,
}) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		camp_id,
		group_id,
		camp_category,
		camp_mid_category,
		revenue_amount,
		event: 'selectPG_clickCouponCTA',
	});
}

export function selectPG_downloadCoupon({
	user_no,
	user_type,
	email,
	camp_id,
	group_id,
	camp_category,
	camp_mid_category,
	revenue_amount,
}) {
	dataLayerPush({
		user_no,
		user_type,
		email,
		camp_id,
		group_id,
		camp_category,
		camp_mid_category,
		revenue_amount,
		event: 'selectPG_downloadCoupon',
	});
}

export function submitSurvey({
	user_no,
	email,
	name,
	camp_id,
	group_id,
	where,
	membership,
	mentor_nickname,
	revenue_type,
}) {
	dataLayerPush({
		user_no,
		email,
		name,
		camp_id,
		group_id,
		where,
		membership,
		mentor_nickname,
		revenue_type,
		event: 'submitSurvey',
	});
}
